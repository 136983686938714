const Translate = {
  home: [ "Casa", "Home" ],
  classroom: [ "Sala de aula", "Classroom" ],
  login: [ "Entrar", "Log in" ],
  error: [ "Erro de login", "Log in error" ],
  start: [ "Iniciar", "Start" ],
  back: [ "Voltar", "Back" ],
  access: [ "Acesso Negado", "Access Denied" ],
  password: [ "Senha", "Password" ],
}

export default Translate;