import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect } from "react";


function WebGazerAPI({ APIs, send, Active }) {

  function handleWebGazerToUnity(gazer) {
    // console.log(gazer);
    if (!gazer) return;
    const { x, y } = gazer;
    // const dateTime = dayjs().toJSON();
    dayjs.extend(utc);
    const a = dayjs.utc();
    const dateTime = a.local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]').toString();
    send("React",  "addWebGazer", JSON.stringify({ x, y, date: dateTime }));
  }

  useEffect(() => {
    let dataGazer;
    const callGazer = async () => {
      // console.log("WebGazerAPI");
      const webgazer = window.webgazer;
      webgazer.showVideoPreview(false);
      webgazer.showPredictionPoints(false);
      if (Active) {
        dataGazer = await webgazer.setGazeListener((data, timestamp) => {
          if (APIs)
            handleWebGazerToUnity(data);
        }).begin();
      } else {
        webgazer.setGazeListener().pause();
      }
    }
    callGazer();
  }, [APIs, Active]);
}

export default WebGazerAPI;
