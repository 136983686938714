import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuImage from '../images/Logo_final_completo.png';
import "../Styles/Menu.css";
import { postCanAccess } from "../Request/Requests";
import T from "../utils/Translate";


function Menu() {
  const [login, setLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const [canAccess, setCanAccess] = useState(false);
  const navigate = useNavigate();
  const iTranslate = 0;// XXX: PTBR
  // const iTranslate = 1;// XXX: ENG

  // const maxTime = 180;

  const goGameHouse = () => {
    navigate("/Home");
  }
  
  const goGameAcademy = () => {
    navigate("/Academy");
  }

  const handleLogin = () => {
    setLogin(!login);
    if (!login) {
      setPassword("");
    } else {
      setMsg(false);
    }
  }

  const validate = () => {
    if (password.length > 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    validate();
  }, [minutes, password]);

  const handleResponse = ({ status }) => {
    if (status === 200) {
      localStorage.setItem("Academy", "true");
      localStorage.setItem("Minutes", minutes);
      goGameAcademy();
    }
  };

  const handleError = (_) => {
    setMsg(true);
    setCanAccess(false);
  }

  useEffect(() => {
    if (canAccess && password !== "") {
      postCanAccess(password, handleResponse, handleError);
    }
  }, [canAccess])

  const checkLogin = () => {
    setCanAccess(true);
  }

  const handleChange = ({ target: { value } }) => {
    setPassword(value);
    if (msg)
      setMsg(false);
  }

  const handleMinutes = ({ target: { value } }) => {
    const trueValue = Number(value);

    /* if (trueValue > maxTime) {
      console.log("if");
      setMinutes(180);
    } else */ if (trueValue <= 1 || isNaN(trueValue)) {
      setMinutes(1);
    } else {
      setMinutes(trueValue);
    }
  }

  return(
    <div className="main-page">
      <div className="container-menu">
        <section className="image-section">
          <img src={ MenuImage } className="menu-image" alt="Logo" />
        </section>

        <section className="button-section">
          <button
            type="button"
            className={ canAccess ? "wait" : "button-menu" }
            onClick={ goGameHouse }
            disabled={ canAccess }
          >
            { T.home[iTranslate] }
          </button>

          <button 
            type="button"
            className={ canAccess ? "wait" : "button-menu" }
            onClick={ handleLogin }
            disabled={ canAccess }
          >
            { T.classroom[iTranslate] }
          </button>
          
          <input
            type="number"
            name="minutes"
            min="1"
            // max={ maxTime }
            className={ canAccess ? "wait" : "input-menu" }
            value={ minutes }
            onChange={ handleMinutes }
            disabled={ !login || canAccess }
          />

          <input
            type="password"
            name="password"
            autoComplete="off"
            placeholder={ T.password[iTranslate] }
            className={ canAccess ? "wait" : "input-menu" }
            value={ password }
            onChange={ handleChange }
            disabled={ !login || canAccess }
          />
          <button
            type="button"
            className={ canAccess ? "wait" : "button-menu" }
            onClick={ checkLogin }
            disabled={ disabled || !login || canAccess }
          >
            { T.start[iTranslate] }
          </button>
          <span
            className={ msg ? "error-msg-true" : "error-msg-false" }
          >
            { T.error[iTranslate] }
          </span>
          VERSÃO 2
        </section>
      </div>
    </div>
  );
};

export default Menu;
