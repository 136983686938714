import { useState, useEffect, useRef } from "react";
import { useExternalScript } from "../helpers/externalScriptsLoader";
import { getAiSdkControls } from "../helpers/loader";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";


function MorphCast({ APIs, send }) {
  const mphToolsState = useExternalScript("https://sdk.morphcast.com/mphtools/v1.0/mphtools.js");
  const aiSdkState = useExternalScript("https://ai-sdk.morphcast.com/v1.16/ai-sdk.js");
  const videoEl = useRef(undefined)

  useEffect(() => {
    videoEl.current = document.getElementById("videoEl");
    async function getAiSdk() {
      if(aiSdkState === "ready" && mphToolsState === "ready") {
        const { source, start, stop } = await getAiSdkControls();
        await source.useCamera({
          toVideoElement: document.getElementById("videoEl"),
        });
        if (APIs) {
          await start();
        }
        else
        {
          if (!source.stopped) {
            await stop();
          }
        }
      }
    }
    getAiSdk();
  }, [aiSdkState, mphToolsState, APIs]);


  const [angry, setAngry] = useState(0);
  const [disgust, setDisgust] = useState(0);
  const [fear, setFear] = useState(0);
  const [happy, setHappy] = useState(0);
  const [sad, setSad] = useState(0);
  const [surprise, setSurprise] = useState(0);
  const [neutral, setNeutral] = useState(0);

  const timeout = useRef(undefined);
  useEffect(() => {
    function resetTimeout() {
      let to = timeout.current;
      clearTimeout(to);
      to = setTimeout(() => {
        setAngry(0);
        setDisgust(0);
        setFear(0);
        setHappy(0);
        setSad(0);
        setSurprise(0);
        setNeutral(0);
      }, 3000)
      timeout.current = to;
    }

    function bindEvent() {
      window.addEventListener("CY_FACE_EMOTION_RESULT", handleEmotionEvent)
    }

    function handleEmotionEvent(evt) {
      resetTimeout();
      //console.log(evt.detail.output.emotion);
      setAngry(evt.detail.output.emotion.Angry * 100);
      setDisgust(evt.detail.output.emotion.Disgust * 100);
      setFear(evt.detail.output.emotion.Fear * 100);
      setHappy(evt.detail.output.emotion.Happy * 100);
      setSad(evt.detail.output.emotion.Sad * 100);
      setSurprise(evt.detail.output.emotion.Surprise * 100);
      setNeutral(evt.detail.output.emotion.Neutral * 100);

      handleMorphCastToUnity(evt.detail.output.emotion);
    }

    bindEvent();
  }, []);

  function handleMorphCastToUnity(emo) {
    dayjs.extend(utc);
    const a = dayjs.utc();
    const dateTime = a.local().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]').toString();
    const emotion = {
      angry: emo.Angry * 100,
      disgust: emo.Disgust * 100,
      fear: emo.Fear * 100,
      happy: emo.Happy * 100,
      sad: emo.Sad * 100,
      surprise: emo.Surprise * 100,
      neutral: emo.Neutral * 100,
      date: dateTime
    };
    // console.log(emotion);
    send("React", "addMorphCast", JSON.stringify(emotion))
  }
};

export default MorphCast;
