import React, { useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate } from "react-router-dom";
import { postRegisterEngagement } from "../Request/Requests";

function GameHome() {
  const navigate = useNavigate();
  const [exit, setExit] = useState(false);
  const [visibility, setVisibility] = useState(true);
  const [engagement, setEngagement] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [register, setRegister] = useState();

  

  const { unityProvider, isLoaded, sendMessage, addEventListener, removeEventListener, unload } = useUnityContext({
    loaderUrl: "build/UnityWebgl.loader.js",
    dataUrl: "build/UnityWebgl.data",
    frameworkUrl: "build/UnityWebgl.framework.js",
    codeUrl: "build/UnityWebgl.wasm",
  });

  useEffect(() => {
    if (isLoaded) {
      sendMessage("React", "setHome");
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded && !!token && !!userId && visibility)
      setEngagement(true);
    else
      setEngagement(false);
  }, [isLoaded, !!token, !!userId, visibility]);

  const callEngagement = (contextId) => {
    if (!userId || !token) return;
    postRegisterEngagement({ jwt: token, id: userId , contextId}, (x) => console.log(x), (x) => console.log(x));
  };

  useEffect(() => {
    if (engagement) {
      callEngagement("1");
      setRegister(setInterval(() => {
        callEngagement("2");
      }, 10000));
    } else {
      clearInterval(register);
    }
  }, [engagement])

  const handleGoHome = useCallback(() => {
    setExit(true);
  }, []);

  const handleLogin = useCallback((jwt, id) => {
    setToken(jwt);
    setUserId(id);
  }, []);

  const handleLogoutUser = useCallback(() => {
    setUserId("");
    setToken("");
  }, []);

  useEffect(() => {
    if (exit) {
      const close = async () => {
        unload();
      }
      close();
      navigate("/");
    }
  }, [exit]);

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible")
        setVisibility(true);
      else
        setVisibility(false);
    });
  }, []);

  useEffect(() => {
    addEventListener("LogoutUser", handleLogoutUser);
    return () => {
      removeEventListener("LogoutUser", handleLogoutUser)
    };
  }, [addEventListener, removeEventListener, handleLogoutUser]);

  useEffect(() => {
    addEventListener("GoHome", handleGoHome);
    return () => {
      removeEventListener("GoHome", handleGoHome)
    };
  }, [addEventListener, removeEventListener, handleGoHome]);

  useEffect(() => {
    addEventListener("LoginUser", handleLogin);
    return () => {
      removeEventListener("LoginUser", handleLogin)
    };
  }, [addEventListener, removeEventListener, handleLogin]);

  return(
    <div className="unity-container">
      <Unity unityProvider={ unityProvider } className="unity" />
    </div>
  );
}

export default GameHome;
