import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import GameAcademy from "./Pages/GameAcademy";
import GameHome from "./Pages/GameHome";
import Menu from "./Pages/Menu";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" Component={ Menu } />
        <Route exact path="/Academy" Component={ GameAcademy } />
        <Route exact path="/Home" Component={ GameHome } />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
