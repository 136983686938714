import React, { useEffect, useState, useCallback } from "react";
import WebGazerAPI from "../Components/WebGazerAPI";
import MorphCast from "../Components/MorphCast";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate } from "react-router-dom";
import MenuImage from '../images/Logo_final_completo.png';
import { postValidateAccess } from "../Request/Requests";
import jwtDecode from "jwt-decode";
import T from "../utils/Translate";


function GameAcademy() {
  const [APIs, setAPIs] = useState(false);
  const [auth, setAuth] = useState(false);
  const [startExperiment, setStartExperiment] = useState(false);
  const [msg, setMsg] = useState(false);
  const [canAccess, setCanAccess] = useState(false);
  const [exit, setExit] = useState(false);
  const navigate = useNavigate();
  const iTranslate = 0;// XXX: PTBR
  // const iTranslate = 1;// XXX: ENG

  const { isLoaded, unityProvider, sendMessage, addEventListener, removeEventListener, unload } = useUnityContext({
    loaderUrl: "build/UnityWebgl.loader.js",
    dataUrl: "build/UnityWebgl.data",
    frameworkUrl: "build/UnityWebgl.framework.js",
    codeUrl: "build/UnityWebgl.wasm",
  });

  useEffect(() => {
    const academy = localStorage.getItem("Academy");
    const token = localStorage.getItem("Academy_Token");
    const currentTime = parseInt(Date.now() / 1000);
    if (!!academy) {
      setAuth(true);
    } else if (!!token) {
      const exp = jwtDecode(token).exp;
      if (exp < currentTime) {
        localStorage.removeItem("Academy");
        localStorage.removeItem("Academy_Token");
        localStorage.removeItem("Minutes");
        navigate("/");
      }
    }
    else
      navigate("/");
  }, [])  

  useEffect(() => {
    if (isLoaded) {
      sendMessage("React", "setAcademy");
    }
  }, [isLoaded]);

  const handleAPIs = useCallback((onOff) => {
    setAPIs(onOff === 1);
  }, []);

  useEffect(() => {
    addEventListener("OnOffAPIs", handleAPIs);
    return () => {
      removeEventListener("OnOffAPIs", handleAPIs)
    };
  }, [addEventListener, removeEventListener, handleAPIs]);

  useEffect(() => {
    if (exit) {
      const close = async () => {
        unload();
      };
      close();
      navigate("/");
    }
  }, [exit]);

  const handleGoHome = useCallback(() => {
    localStorage.removeItem("Academy");
    localStorage.removeItem("Academy_Token");
    localStorage.removeItem("Minutes");
    setExit(true);
    //navigate("/");
  }, []);

  useEffect(() => {
    addEventListener("GoHome", handleGoHome);
    return () => {
      removeEventListener("GoHome", handleGoHome)
    };
  }, [addEventListener, removeEventListener, handleGoHome]);

  const handleSendMessage = (gbj, func, data) => {
    if (sendMessage) {
      sendMessage(gbj, func, data);
    }
  }

  const handleResponse = (response) => {
    const token = response.data;
    localStorage.setItem("Academy_Token", token);
    localStorage.removeItem("Academy");
    localStorage.removeItem("Minutes");
    setStartExperiment(true);
  }

  const handleError = () => {
    setMsg(true);
  }

  useEffect(() => {
    if (canAccess) {
      if (localStorage.getItem("Academy") === "true" && !!localStorage.getItem("Minutes")) {
        const min = Number(localStorage.getItem("Minutes"));
        postValidateAccess(min, handleResponse, handleError);
      } else {
        setMsg(true);
      }
    }
  }, [canAccess])

  const start = () => {
    setCanAccess(true);
  }

  const goToMenu = () => {
    localStorage.removeItem("Academy");
    localStorage.removeItem("Academy_Token");
    localStorage.removeItem("Minutes");
    navigate("/");
  }

  return(
    <>
      { startExperiment ? (
        <div className="unity-container">
          {auth && <Unity className="unity" unityProvider={ unityProvider }/>}
          { isLoaded && <MorphCast APIs={ APIs } send={ handleSendMessage } /> }
          { isLoaded && <WebGazerAPI Active={ isLoaded } APIs={ APIs } send={ handleSendMessage } /> }
        </div>)
        :
        <div className="main-page">
          <div className="container-menu">
            <section className="image-section">
              <img src={ MenuImage } className="menu-image" alt="Logo" />
            </section>

            <section className="button-section">
              <button 
                type="button"
                className="button-menu"
                onClick={ start }
              >
                { T.start[iTranslate] }
              </button>
              
              <button
                type="button"
                className="button-menu"
                onClick={ goToMenu }
              >
                { T.back[iTranslate] }
              </button>
              
              <span
                className={ msg ? "error-msg-true" : "error-msg-false" }
              >
                { T.access[iTranslate] }
              </span>
            </section>
          </div>
        </div>
      }
    </>
  );
}

export default GameAcademy;
